<template>
    <div class="notice">
        <div class="notice_body">
            <div>
                <template v-if="list.length > 0">
                    <div class="card" v-for="(item, index) in list" :key="index">
                        <div class="card-title" @click="showQrcode(item)">
                            <div class="site-name">{{ item.name }}，电话：{{item.mobile}}</div>
                            <div class="number">{{ item.goods_number }}个商品</div>
                        </div>
                        <div class="card-body">
                            <div class="goods-list">
                                <div
                                        class="goods-info"
                                        v-for="(goods, goodsIndex) in item.goods"
                                >
                                    <div class="goods-img">
                                        <img :src="goods.path" />
                                    </div>
                                    <div class="info">
                                        <div class="goods-title">
                                            {{ goods.title }}
                                        </div>
                                        <div class="goods-specs">
                                            {{ goods.spec_text ? goods.spec_text : "默认规格" }}
                                        </div>
                                    </div>
                                    <div class="goods-number">× {{ goods.total }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <nut-popup
                v-model="show"
                round
                position="bottom"
                :style="{ height: '90%' }"
                :close-on-click-overlay="false"
        >
            <div class="confirm-window">
                <div class="title">配送详情</div>
                <div class="content">
                    <div class="site-info">
                        <div class="site-name">{{ record.site_name }}</div>
                        <div class="site-leader" @click="contact(record.mobile)">
                            配送员：{{ record.name }} 手机号码：{{ record.mobile }}
                        </div>
                    </div>
                    <div class="qrcode">
                        <img :src="qrcode" />
                        {{ showOrderSn }}
                    </div>
                    <div class="warning">
                        您可以在收货完成后将此码提供给配送员进行扫描，配送员扫描此送货码并确认送货成功后才会更新对应订单的状态
                    </div>

                    <div class="buttons">
                        <button class="btn" @click="hideWindow">点击关闭</button>
                    </div>
                </div>
            </div>
        </nut-popup>
    </div>
</template>

<script>
  export default {
    name: 'sending-goods',
    data(){
      return {
        list:[],
        show: false,
        record: {},
        qrcode: "",
        showOrderSn: "",
      }
    },
    mounted () {
      document.title = '正在配送中的商品'
      this.getSendingGoodsList()
    },
    methods: {
      getSendingGoodsList () {
        this.$api.community.points.pointWillReceiveGoods().then(response => {
          this.list = response.data;
        })
      },
      showQrcode(record) {
        this.record = record;
        let params = {
          delivery_id: record.delivery_id,
          goods: record.goods.map((item) => {
            return {
              goods_id: item.goods_id,
              spec_ids: item.spec_ids,
              spec_text: item.spec_text,
              total: item.total,
              title: item.title,
              path: item.path,
            };
          }),
        };
        this.$api.community.points
          .getPointSendReceiveCode(params)
          .then((response) => {
            this.qrcode = response.data.url + "?rand=" + Math.random();
            this.showOrderSn = response.data.sn;
          });
        this.show = true;
      },
      hideWindow() {
        this.show = false;
        this.qrcode = "";
        this.showOrderSn = "";
      },
      contact(mobile){
        self.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          url:
              '/web/makePhoneCall/common?mobile=' + mobile,
        })
      }
    }
  }
</script>

<style scoped lang="scss">
    @include b(notice) {
        width: 100vw;
        height: 100vh;
        background: #f6f6f6;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        @include e(body) {
            flex-grow: 1;
            box-sizing: border-box;
            padding: 15px 0;
            overflow-y: scroll;
        }
    }

    .card {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        width: 345px;
        margin: 0 auto;
        margin-bottom: 15px;

        .card-title {
            flex: 1;
            display: flex;
            align-items: center;
            height: 48px;
            background: linear-gradient(129deg, #fbf2e2 0%, #f1e2c9 100%);
            border-radius: 10px 10px 0px 0px;
            padding: 0 10px;
            color: #6c4806;

            .site-name {
                flex: 1;
                font-size: 14px;
            }

            .number {
                width: 50px;
                display: flex;
                justify-content: flex-end;
                font-size: 14px;
            }
        }

        .card-body {
            width: 100%;
            background: #fff;
            border-radius: 0px 0px 10px 10px;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            padding: 10px 10px 0 10px;

            .goods-list {
                flex: 1;
                display: flex;
                flex-direction: column;

                .goods-info {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 10px;

                    .goods-img {
                        width: 50px;
                        height: 50px;
                        -webkit-border-radius: 5px;
                        -moz-border-radius: 5px;
                        border-radius: 5px;
                        overflow: hidden;
                        margin-right: 10px;

                        img {
                            width: 50px;
                            height: 50px;
                        }
                    }

                    .info {
                        flex: 1;
                        display: flex;
                        flex-direction: column;

                        .goods-title {
                            font-size: 16px;
                        }

                        .goods-specs {
                            font-size: 14px;
                            color: #ccc;
                            margin-top: 6px;
                        }
                    }

                    .goods-number {
                        width: 80px;
                        display: flex;
                        align-items: baseline;
                        justify-content: flex-end;
                        font-size: 16px;
                        color: red;
                    }
                }
            }
        }
    }

    .confirm-window {
        display: flex;
        flex-direction: column;

        .title {
            flex: 1;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(0, 0, 0, 0.6);
            padding: 15px 0;
        }

        .content {
            font-size: 16px;
            color: #333;
            padding: 10px 20px;
            line-height: 25px;
            display: flex;
            flex-direction: column;

            .site-info {
                flex: 1;
                border: #ccc dashed 1px;
                padding: 5px 10px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
                background: #fff9ec;
                color: #333;

                .site-name {
                    font-size: 16px;
                }

                .site-leader {
                    font-size: 14px;
                    color: #999;
                }
            }

            .qrcode {
                flex: 1;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                img {
                    width: 80%;
                }
            }

            .warning {
                padding: 20px 20px 10px 20px;
                text-indent: 20px;
                font-size: 14px;
                color: #999;
            }
        }
    }

    .buttons {
        background: #fff;
        height: 50px;
        padding: 10px 50px;
        display: flex;

        .btn {
            flex: 1;
            bottom: calc(30px + env(safe-area-inset-bottom));
            bottom: calc(30px + constant(safe-area-inset-bottom));
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
            background-clip: padding-box;
            border: 4px solid rgba(244, 106, 23, 0.15);
            border-radius: 29px;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 400;
            color: #ffffff;
        }
    }
</style>